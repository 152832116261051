import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MustMatch } from './mustMatch';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { config } from 'src/app/constants/config';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  EyeFlag: boolean = false;
  confirmEyeFlag: boolean = false;
  spinner: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private snackBar: MatSnackBar,
  ) {
    // ^[A-Z|a-z|0-9]+$
    this.resetForm = this.formBuilder.group(
      {
        // username: new FormControl('', Validators.compose([
        //   Validators.required,
        //   Validators.pattern('^[A-Z|a-z]+$')
        // ])),
        // first_name: new FormControl('', Validators.compose([
        //   Validators.required,
        //   //Validators.pattern('^[A-Z|a-z]+$')
        // ])),
        // last_name: new FormControl('', Validators.compose([
        //   Validators.required,
        //   //Validators.pattern('^[A-Z|a-z]+$')
        // ])),
        newpassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
        confirmPassword: new FormControl('', Validators.required)
      },
      {
        validator: MustMatch('newpassword', 'confirmPassword')
      }
    );

  }

  ngOnInit(): void {
  }

  onEyeClick() {
    this.EyeFlag = !this.EyeFlag;
  }

  onConfirmEyeClick() {
    this.confirmEyeFlag = !this.confirmEyeFlag;
  }


  onResetSubmit() {
    if (this.resetForm.valid) {
      this.spinner = true;
      this.userService.resetPassword(this.resetForm.value).subscribe(
        (res: any) => {
          if (res.status == true) {
            this.spinner = false;
            localStorage.clear();
            sessionStorage.clear();
            this.snackBar.open(res.message, "", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass: ['blue-snackbar']
            });
            location.href = config.base_url;
          }
          if (res.status == false) {
            this.spinner = false;
            this.snackBar.open(res.message, "", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass: ['blue-snackbar']
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.spinner = false;
          this.snackBar.open(error.error.message, "", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass: ['blue-snackbar']
          });

        }
      );
    }
    else {
      this.snackBar.open("Please fill form details", "", {
        duration: 2000,
        verticalPosition: 'top',
        panelClass: ['blue-snackbar']
      });
    }
  }



}
