

<div class="edit_profile register login">
    <div class="container">
        <div class="row  align-items-center">

        <div class="col-lg-5 logtxt">
            <h2>Create, sell or collect digital items.</h2>
        </div>

            <div class="col-lg-4 offset-lg-2">
                <div class="right_part space-y-20">
                    <h1> Reset Password</h1>
                    <h3>
                        <a [routerLink]="['/register']" routerLinkActive="router-link-active">
                            Back to login </a>
                        </h3>
                    <div class="box edit_box w-full space-y-20">
                        <form [formGroup]="forgotForm" (ngSubmit)="onForgotSubmit()">
                            <div class="mb-3">
                                <!-- <span class="nameInput">Email</span> -->
                                <div class="confirm">
                                    <!-- style="background: #1c095f !important;" -->
                                    <input type="text" class="form-control" formControlName="email" placeholder="Email">
                                    <div *ngIf="forgotForm.get('email')?.touched" class="text text-danger">
                                        <div *ngIf="forgotForm.get('email')?.getError('required')">
                                            Email is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="space-y-10 mt-3">
                                <button type="submit" class="btn btn-grad  btn-lg "
                                    [disabled]="!forgotForm.valid">Forgot</button>
                            </div>
                            <div class="space-y-10 mt-3">
                                <button class="forgot" [routerLink]="['/login']" routerLinkActive="router-link-active"  variant="primary">Login</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loader *ngIf="spinner"></app-loader>
