<div class="topheader" id="navbar">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <h1>
        <a class="navbar-brand" routerLink="/">
          <img src="assets/images/nft-logo.png" />
        </a>
      </h1>
      <button aria-controls="navbarSupportedContent" (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed" class="navbar-toggler" type="button" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- <div class="header__search">
        <input type="text" placeholder="Search" />
        <button class="header__result">
          <img src="../assets/images/search.png">
        </button>
      </div> -->

      <div class="collapse navbar-collapse" id="navbarSupportedContent" [collapse]="isCollapsed" [isAnimated]="true">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" [ngClass]="{'active': current_page == ''}">
            <a class="nav-link" routerLink="/" href="javascript:void(0)">Home
              <span class="lines"> </span>
            </a>
          </li>

          <!-- <li class="nav-item dropdown"  dropdown>
            <a id="link-dropdown" class="nav-link dropdown-toggle" href dropdownToggle (click)="false"
              aria-controls="your-dropdown" >
              Marketplace
              <span class="lines"> </span>
            </a>
         
            <div id="your-dropdown" class="dropdown-menu" aria-labelledby="link-dropdown" *dropdownMenu>
              <a class="dropdown-item"  routerLink="/marketplace"  href="javascript:void(0)">
                <i class="fa fa-paint-brush" aria-hidden="true"></i> Art</a>
              <a class="dropdown-item" href="javascript:void(0)" [routerLink]="'/'">
                <i class="fa fa-music" aria-hidden="true"></i> Music</a>
              <a class="dropdown-item" href="javascript:void(0)" [routerLink]="'/'">
                <i class="fa fa-futbol-o" aria-hidden="true"></i> Sports</a>
              
            </div>
          </li> -->


          <!-- <li class="nav-item dropdown"  dropdown>
            <a id="link-dropdown" class="nav-link dropdown-toggle" href dropdownToggle (click)="false"
              aria-controls="your-dropdown" >
              Stats
              <span class="lines"> </span>
            </a>
         
            <div id="your-dropdown" class="dropdown-menu" aria-labelledby="link-dropdown" *dropdownMenu>
              <a class="dropdown-item" href="javascript:void(0)" [routerLink]="'/ranking'">
                Ranking</a>
              <a class="dropdown-item" href="javascript:void(0)" [routerLink]="'/stats'">
                Activity</a>
            
              
            </div>
          </li> -->

          <li class="nav-item">
            <a class="nav-link" [routerLink]="'/collection/mycollection'">NFT Creator
              <span class="lines"> </span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="#" href="javascript:void(0)">Art Election
              <span class="lines"> </span>
            </a>
          </li>

          <li class="nav-item" [ngClass]="{'active': current_page == 'marketplace'}">
            <a class="nav-link" routerLink="/marketplace" href="javascript:void(0)">Marketplace
              <span class="lines"> </span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': current_page == 'stats'}">
            <a class="nav-link" routerLink="/stats" href="javascript:void(0)">Activity
              <span class="lines"> </span>
            </a>
          </li>
          <li *ngIf="loginToken == null" class="nav-item">
            <a class="nav-link" routerLink="/login" href="javascript:void(0)">Login
              <span class="lines"> </span>
            </a>
          </li>
          <li *ngIf="loginToken == null" class="nav-item">
            <a class="nav-link" routerLink="/register" href="javascript:void(0)">Register
              <span class="lines"> </span>
            </a>
          </li>
          <!-- *ngIf="user!=null" -->
          <!-- <li *ngIf="loginToken != null && user == null" class="nav-item">
            <button style="color: white;" (click)="connectWalletAction()"> <img src="./../assets/images/wallet.png">
              {{first_name}} {{last_name}}</button>
          </li> -->
          <li *ngIf="loginToken != null && user == null" class="nav-item">
            <button style="color: white;" id="btnclick" (click)="connectWalletAction()"> <img
                src="./../assets/images/wallet.png">
              {{first_name}} {{last_name}}</button>
          </li>
          <li *ngIf="loginToken != null && user == null" class="nav-item">
            <a class="nav-link" (click)="logoutAction()" href="javascript:void(0)">Logout</a>
          </li>
          <!-- <li class="nav-item" [ngClass]="{'active': current_page == 'login'}" *ngIf="user==null">
          <button  (click)="connectWalletAction()"> <img src="./../assets/images/wallet.png"> </button>
        </li> -->
          <!-- <a class="nav-link" (click)="connectWalletAction()" href="javascript:void(0)">
            <img src="assets/images/wallet.png" alt="wallet">
          </a> -->
          <li class="nav-item dropdown" [ngClass]="{'active': current_page == 'login'}" *ngIf="user!=null" dropdown>
            <a id="link-dropdown" class="nav-link dropdown-toggle" href dropdownToggle (click)="false"
              aria-controls="your-dropdown" *ngIf="user.first_name.length>0">
              <img [src]="mediaBase + '/images/user/' + profile_image" class="profileImage" />{{user.first_name}}
              {{user.last_name}}
            </a>
            <a id="link-dropdown" class="nav-link dropdown-toggle" href dropdownToggle (click)="false"
              aria-controls="your-dropdown" *ngIf="user.first_name.length==0">
              <img [src]="mediaBase + '/images/user/' + profile_image" class="profileImage" />{{user.username}}
            </a>
            <div id="your-dropdown" class="dropdown-menu" aria-labelledby="link-dropdown" *dropdownMenu>
              <a class="dropdown-item" href="javascript:void(0)" [routerLink]="'/profile/'+user.user_id+'/collected'">
                <i class="fa fa-user"></i> My NFTs</a>
              <a class="dropdown-item" href="javascript:void(0)" [routerLink]="'/collection/mycollection'">
                <i class="fa fa-th"></i> My Collection</a>
              <a class="dropdown-item" href="javascript:void(0)" [routerLink]="'/profile/'+user.user_id+'/favorited'">
                <i class="fa fa-heart"></i> My Favourites</a>
              <a class="dropdown-item" href="javascript:void(0)" (click)="logoutAction()">
                <i class="fa fa-sign-out"></i>Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
<router-outlet></router-outlet>

<app-footer></app-footer>