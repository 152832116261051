<div class="appContainer">
    <div class="nft-main ">
       
        <div class="banner-home">
        <div class="container">
    <div class="row">
        <div class="col-md-6">
          
            <div class="banner-txt1">
                <h3>Discover Rare Collection of  <br>Art & NFTs </h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt magna non duis etiam.
                     At nibh sit sed pulvinar dictum. 
                    Ut in at tellus augue donec tincidunt. Laoreet egestas volutpat dictum.</p>
            </div>

            <ul class="banner_lists">
                <li>
                
                    <a href="javascript:void(0)" routerLink="/marketplace">Buy Now</a>
  
                </li>
                </ul>



        </div>

        <div class="col-md-6">
        <div class="image">
            <img src="../../../assets/images/homebanner-img1.png" class="img-fluid">
            <h5>213+<br>Collections</h5>
        </div>
      </div>

    </div>


    </div>
        </div>


        <div class="nft-sell ">
           

            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        
                        <div class="feature-box f-boxed style-3"> <i class="wow fadeInUp bg-color-2 i-boxed icon_wallet"></i>
                            <div class="text">
                              <h4 class="wow fadeInUp">Set up your wallet
                              </h4>
                              <p class="wow fadeInUp" data-wow-delay=".25s">
                                Integrate with any of our existing wallets: Formatic or 
                                Metamask of your choice through your account.
                              </p>
                            </div>
                            <i class="wm icon_wallet"></i>
                          </div>

                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="feature-box f-boxed style-3"> 
                            <i class="wow fadeInUp bg-color-2 i-boxed icon_cloud-upload_alt"></i>
                            <div class="text">
                              <h4 class="wow fadeInUp">Add your NFT's
                              </h4>
                              <p class="wow fadeInUp" data-wow-delay=".25s">
                                Integrate with any of our existing wallets: Formatic or 
                                Metamask of your choice through your account.
                              </p>
                            </div>
                            <i class="wm icon_cloud-upload_alt"></i>
                          </div>
                    </div>


                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="feature-box f-boxed style-3"> 
                            <i class="wow fadeInUp bg-color-2 i-boxed icon_tags_alt"></i>
                            <div class="text">
                              <h4 class="wow fadeInUp">Sell your NFT's
                              </h4>
                              <p class="wow fadeInUp" data-wow-delay=".25s">
                                Integrate with any of our existing wallets: Formatic or 
                                Metamask of your choice through your account.
                              </p>
                            </div>
                            <i class="wm icon_tags_alt"></i>
                          </div>
                    </div>



                </div>
            </div>
        </div>


        <div class="nft-trending" *ngIf="collections.length>0">
            <div class="nft-trending-head">
                <div class="container">
                    <h2>Trending</h2>
                    <ul class="top_tabs">
                        <li [ngClass]="{'active':collectionMenu == ''}" (click)="showItemByCollection('')"><a
                                href="javascript:void(0)">All items</a></li>
                        <li *ngFor="let item of collections" [ngClass]="{'active':item._id == collectionMenu}"
                            (click)="showItemByCollection(item._id)"><a href="javascript:void(0)">{{item.name}}</a></li>
                    </ul>
                </div>
            </div>
            <div class="nft-trending_body">
                <div class="container">
                    <app-itemskeleton *ngIf="spinner"></app-itemskeleton>
                    <div class="row" *ngIf="!spinner && collectionItems.length>0">
                        <div class="col-md-3" *ngFor="let item of collectionItems">
                            <app-item [isUserIP]="isUser" [item]="item" (itemEvent)="itemEvent($event)"> </app-item>
                        </div>
                    </div>
                    <div class="row" *ngIf="!spinner && collectionItems.length==0">
                        <div class="col-md-9" style="text-align: center;">
                            <span style="color: White">No Items Found</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        
        <div class="nft-category">
            <div class="nft-category-head mb-50">
                <div class="container text-center">
                    <h2>Browse By Category</h2>
                   
                </div>
            </div>
            <div class="nft-category-content">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-lg-3 " *ngFor="let category of categories">
                            <div class="item" [routerLink]="['/marketplace']"
                                [queryParams]="{category_id: category._id}">
                                <div class="nft-category-listing">
                                    <img [src]="mediaBase + '/images/categories/' + category.category_image">
                                    <div class="nft-category-listing-overlay"></div>
                                    <ul class="ntf-category-foot">
                                        <li>
                                            <h4>{{category.title}}</h4>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>

<div class="section__artists mt-100 mb-50" *ngIf="hotcollections">
    <div class="container">

        <div class="section_head">
            <h2 class="section__title text-center">Hot Collections</h2>
        </div>



    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let ht of hotcollections">
            <div class="nft_coll">
                <div class="nft_wrap">
                    <span>
                    <img style="height:300px" [src]="mediaBase + '/images/collection/logo/' + ht.image" class="lazy img-fluid" alt="">
                </span>
            </div>
                <div class="nft_coll_pp">
                    <span><img class="lazy" src="../../../assets/images/favicon.png" alt="">
                    </span>
                    <i class="fa fa-check"></i>
                </div>
                <div class="nft_coll_info"><span>
                        <h4>{{ht.name}}</h4>
                    </span>
                </div>
            </div>
        </ng-template>  
   
      
      
      </owl-carousel-o>





</div>
</div>

<div class="section__artists mt-100 mb-50">
    <div class="container">

        <div class="section_head">
            <h2 class="section__title text-center">New Items</h2>
        </div>


        <div class="row mt-50" *ngIf="newitems">

            <div class="col-md-6 col-lg-4" *ngFor="let nwitem of newitems;">
                <div class="d-item" [routerLink]="'/item/view/' + nwitem._id">
                    <div class="nft__item">
                       
              
                        <div class="nft__item_wrap" style="height: 250px;">
                            <div><span>
                                <img [src]="mediaBase+'/images/item/thumb/'+nwitem.thumb" class="lazy nft__item_preview" alt=""></span></div>
                        </div>
                        <div class="nft__item_info"><span>
                                <h4>{{nwitem.name}}</h4>
                            </span>
                            <div class="nft__item_price"> {{nwitem.price}} {{configcurrency}}</div>
                            <div class="nft__item_like"><i class="fa fa-heart"></i><span>{{nwitem.like_count}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
           

        </div> 


    </div>
</div>


<section class="communitySec">
    <div class="container">
        <div class="row">
            <div class="communitySec_bg">
                <div class="col-md-7">
                    <h4>Community</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt magna non duis etiam. At nibh
                        sit sed pulvinar dictum.</p><a href="https://discord.gg/WVyVMXej" target="_blank"><img src="../../../assets/images/discordbtn.png" class="img-fluid"></a>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="mynftSec">
    <div class="container">
        <div class="row">
            <div class="mynftSec_bg">
                <div class="col-md-7">
                    <h4>Upcomming Drop</h4>
                    <h3>MyNft</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt magna non duis etiam. At nibh
                        sit sed pulvinar dictum.</p>
                        <a href="#" class="btn-main">Register now</a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="section__artists mt-100 mb-50">
    <div class="container">

        <div class="section_head">
            <h2 class="section__title text-center">Top Sellers</h2>
        </div>

        <div class="col-lg-12">
            <div>
                <ol class="author_list">
                    <li *ngFor="let seller of topsellers;">
                        <div class="author_list_pp">
                            <span *ngIf="seller.profileimg"><img [src]="mediaBase+'/images/user/'+seller.profileimg" height="50px">
                            <i class="fa fa-check"></i></span>
                            <span *ngIf="!seller.profileimg"><img src="../../../assets/images/nouser.jpg" height="50px">
                                <i class="fa fa-check"></i></span>
                        </div>
                        <div class="author_list_info"><span>{{seller.user_name | uppercase}}</span><span class="bot">{{seller.sellprice | number:'1.5-5'}} {{configcurrency}}</span></div>
                    </li>
           
                </ol>
            </div>
        </div>



</div>
</div>

<app-loader *ngIf="spinner"></app-loader>