import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MustMatch } from './mustMatch';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  EyeFlag: boolean = false;
  confirmEyeFlag: boolean = false;
  spinner: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private snackBar: MatSnackBar,
  ) {
    this.registerForm = this.formBuilder.group(
      {
        username: new FormControl('', Validators.compose([
          Validators.required,
          Validators.pattern('^[A-Z|a-z]+$')
        ])),
        first_name: new FormControl('', Validators.compose([
          Validators.required,
        ])),
        last_name: new FormControl('', Validators.compose([
          Validators.required,
        ])),
        email: new FormControl('', [Validators.email]),
        password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        confirmPassword: new FormControl('', Validators.required)
      },
      {
        validator: MustMatch('password', 'confirmPassword')
      }
    );

  }

  ngOnInit(): void {
  }

  onEyeClick() {
    this.EyeFlag = !this.EyeFlag;
  }

  onConfirmEyeClick() {
    this.confirmEyeFlag = !this.confirmEyeFlag;
  }


  onRegisterSubmit() {
    if (this.registerForm.valid) {
      this.spinner = true;
      this.userService.registerUser(this.registerForm.value).subscribe(
        (res: any) => {
          if (res.status == true) {
            this.spinner = false;
            this.snackBar.open(res.message, "", { duration: 2000,
          verticalPosition: 'top',
          panelClass: ['blue-snackbar'] });
            this.router.navigate(['/login']);
          }
          if (res.status == false) {
            this.spinner = false;
            this.snackBar.open(res.message, "", { duration: 2000,
          verticalPosition: 'top',
          panelClass: ['blue-snackbar'] });
          }
        },
        (error: any) => {
          this.spinner = false;
          this.snackBar.open("Uploaded file should be JPEG/PNG", "", { duration: 2000,
          verticalPosition: 'top',
          panelClass: ['blue-snackbar'] });

        }
      );
    }
    else {

      this.snackBar.open("Please fill form details", "", { duration: 2000,
          verticalPosition: 'top',
          panelClass: ['blue-snackbar'] });
    }
  }



}
