import { Component, OnInit } from '@angular/core';
import { CmsService } from '../services/cms.service';
import { PushNotificationsService } from 'ng-push-ivy';
import { ItemService } from 'src/app/services/item.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  termsConditionId: any;
  id:any;
  categories: any = [];
  termsConditionInfo: any;
  privacyPolicyId: any;
  privacyPolicyInfo: any;
  helpCenterId: any;
  helpCenterInfo: any;
  careerId: any;
  careerInfo: any;
  socialMediaId: any;
  socialMediaInfo: any;
  contactUsId: any;
  contactUsInfo: any;
  blogsId: any;
  blogsInfo: any;
  faqsId: any;
  faqsInfo: any;
  templatesId: any;
  templatesInfo: any;
  socialInfo: any;
  user: any;

  constructor(
    private cmsService: CmsService,
    private itemService: ItemService,
    private userService: UserService,
    private _pushNotifications: PushNotificationsService
  ) { }

  ngOnInit() {

    //this._pushNotifications.requestPermission();



  //   this._pushNotifications.create(
  //     'Muzella',
  //     {
  //       body: 'Muzella Example Test Notification',
  //       dir:'ltr',
  //       silent:false
  //     },
      
  // )
  //     .subscribe(res => {
  //         if (res.event.type === 'click') {
  //             res.notification.close();
  //         }
  //     }
  // )
  
  

  this.id = setInterval(() => {
   
  }, 1000);

  this.user = localStorage.getItem("userid");

    this.getTermsConditions();
    this.getPrivacyPolicy();
    // this.getHelpCenter();
    this.getCareers();
    // this.getSocialMedia();
    this.getContactUs();
    this.getBlogs();
    this.getFaqs();
    this.getCategory();
    this.getSocialLinks();


    
  }

  getCategory = () => {
    this.itemService.categoryList().subscribe(result => {
      for (let index = 0; index < result.tempArray.length; index++) {
        const element = result.tempArray[index];
        this.categories.push(element);
        if (index == 4) {
          break;
        }
      }
    })
    setTimeout(() => {
    }, 1000);
  }

  getTermsConditions() {
    this.cmsService.getTermsConditionsById().subscribe(
      (res: any) => {
        if (res.status == true) {
          this.termsConditionInfo = res.data.docs[0];
        }
      }
    )
  }

  getPrivacyPolicy() {
    this.cmsService.getPrivacyPolicyById().subscribe(
      (res: any) => {
        if (res.status == true) {
          this.privacyPolicyInfo = res.result[0];
        }
      }
    )
  }

  getHelpCenter() {
    this.cmsService.getHelpCentersById().subscribe(
      (res: any) => {
        // console.log(res)
        if (res.status == true) {
          this.helpCenterInfo = res.data.docs[0];
        }
      }
    )
  }

  getCareers() {
    this.cmsService.getCareerById().subscribe(
      (res: any) => {
        if (res.status == true) {
          this.careerInfo = res.result[0];
        }
      }
    )
  }

  getSocialMedia() {
    this.cmsService.getSocialMediaById().subscribe(
      (res: any) => {
        if (res.status == true) {
          // this.socialMediaInfo = res.result;
          // console.log(this.socialMediaInfo);
        }
      }
    )
  }

  getContactUs() {
    this.cmsService.getContactUsById().subscribe(
      (res: any) => {
        if (res.status == true) {
          // this.contactUsInfo = res.result;
          // console.log(this.contactUsInfo);
        }
      }
    )
  }

  getBlogs() {
    this.cmsService.getBlogById().subscribe(
      (res: any) => {
        if (res.status == true) {
          this.blogsInfo = res.result[0];
          // console.log(this.blogsInfo);
        }
      }
    )
  }

  getFaqs() {
    this.cmsService.getFaqById().subscribe(
      (res: any) => {
        if (res.status == true) {
          this.faqsInfo = res.result[0];
          // console.log(this.faqsInfo);
        }
      }
    )
  }

  getTemplates() {
    this.cmsService.getEmailTempList().subscribe(
      (res: any) => {
        if (res.status == true) {
          this.templatesId = res.result[0]['_id'];
          this.cmsService.getEmailTempById(this.templatesId).subscribe(
            (res: any) => {
              if (res.status == true) {
                this.templatesInfo = res.result;
              }
            }
          )
        }
      }
    )
  }

  getSocialLinks() {
    this.cmsService.getSocialLinks().subscribe(
      (res: any) => {
        if (res.status == true) {
          this.socialInfo = res.data;
        }
      }
    )
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

}
