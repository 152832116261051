<div class="d-item">
    <div class="nft__item">

        <!-- <div class="de_countdown">
            <div>
                <div class="Clock-days">00d</div>
                <div class="Clock-hours">00h</div>
                <div class="Clock-minutes">00m</div>
                <div class="Clock-seconds">00s</div>
            </div>
        </div> -->
        <div class="author_list_pp">
            <span><img class="lazy" src="../../../assets/images/author/author-2.jpg" alt="">
                <i class="fa fa-check"></i></span>
            </div>

        <div class="nft__item_wrap" style="height: 250px;" (click)="viewAction()">
        <img [src]="mediaBase + '/images/item/thumb/' + item.thumb" class="collectionItemImgBanner" />
    </div>
    <div class="" style="position:relative;">
        <div class="nft__item_info">
           <h4>{{item.name}}</h4> 
           <!-- <h4>enableAuction: {{item.enableAuction}}, enableTimestamp:{{item.enableTimestamp}}, status: {{item.status}}</h4>  -->
        </div>
        <div  class="collectionItemCount" *ngIf="item.enableAuction == true && item.enableTimestamp == true && item.status == 'active'">
            <countdown #cd [config]="{leftTime:item.difference}" (event)="handleEvent($event,item)"></countdown>
        </div>
        <div class="collectionItemCount">
            {{item.price}} {{configcurrency}}
        </div>
        <!-- <div class="collectionItemCount">
            item status: {{item.status}}, publish: {{item.publishStatus}}, market:{{item.marketplaceStatus}}, isUserIP: {{isUserIP}}
        </div> -->
        <!-- item.marketplaceStatus == false && -->
        <div class="moreAction dropdown dropright dotcls" dropdown *ngIf="(this.loginToken && this.loginToken.user_id == item.current_owner && isUserIP == true)"> <!-- *ngIf="isUserIP == true" */  *ngIf="isLoginUserDetail.user_id == item._id" *ngIf="item.isEditOptionCheck == true" -->
            <button [id]="'morelink-dropdown'" class="btn" dropdownToggle aria-controls="more-dropdown">
                <i class="icon-options-vertical"></i>
                <!-- <i class="icon-options-vertical-white"></i> -->
            </button>
            <div [id]="'more-dropdown'" class="dropdown-menu dropdown-menu-right" aria-labelledby="morelink-dropdown"
                *dropdownMenu>
                <a *ngIf="item.status == 'inactive' && item.publishStatus == false" class="dropdown-item"
                    href="javascript:void(0)" (click)="editAction()">Edit</a>

                <a *ngIf="item.status == 'inactive' && item.publishStatus == true" class="dropdown-item"
                    href="javascript:void(0)" (click)="editMint()">Edit Mint</a>

                <a *ngIf="item.status == 'inactive' && item.publishStatus == false" class="dropdown-item"
                    href="javascript:void(0)" (click)="deleteAction()">Delete</a>

                <a *ngIf="item.status == 'inactive' && item.publishStatus == false" class="dropdown-item"
                    href="javascript:void(0)" (click)="publishAction()">Mint</a>

                <a *ngIf="item.status == 'inactive' && item.publishStatus == true" class="dropdown-item"
                    href="javascript:void(0)" (click)="listNftAction()">List Nft</a>
                <!-- market place  -->
                <a *ngIf="item.status == 'active' && item.enableAuctionStatus == false" class="dropdown-item"
                    href="javascript:void(0)" (click)="delistNftAction()">Delist Nft</a>
            </div>
        </div>


        <div class="moreAction dropdown dropright" dropdown style="display: none">
            <button  class="btn" dropdownToggle aria-controls="more-dropdown">
                <i class="icon-options-vertical"></i>
                <!-- <i class="icon-options-vertical-white"></i> -->
            </button>
            <div class="dropdown-menu dropdown-menu-right"  *dropdownMenu>
                <a class="dropdown-item" href="javascript:void(0)">Edit</a>

                <a class="dropdown-item" href="javascript:void(0)">Edit Mint</a>
            </div>
        </div>



        
        <!-- <a class="buy_btn" routerLink="/">Buy Now</a> -->
    </div>
</div>
</div>