import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { config } from 'src/app/constants/config';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  forgotForm: FormGroup;
  EyeFlag: boolean = false;
  confirmEyeFlag: boolean = false;
  loginToken: any;
  spinner: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private snackBar: MatSnackBar,
  ) {
    this.forgotForm = this.formBuilder.group(
      {
        email: new FormControl('', [Validators.required])
      }
    );

  }

  ngOnInit(): void {
  }

  onForgotSubmit() {

    if (this.forgotForm.valid) {
      this.spinner = true;
      this.userService.forgotPassword(this.forgotForm.value).subscribe(
        (res: any) => {
          if (res.status == true) {
            this.spinner = false;
            this.snackBar.open(res.message, "", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass: ['blue-snackbar']
            });
            this.router.navigate(['/login']);
          }
          if (res.status == false) {
            this.spinner = false;
            this.snackBar.open(res.message, "", {
              duration: 2000,
              verticalPosition: 'top',
              panelClass: ['blue-snackbar']
            });
          }
        },
        (error: any) => {
          this.spinner = false;
          this.snackBar.open("Uploaded file should be JPEG/PNG", "", {
            duration: 2000,
            verticalPosition: 'top',
            panelClass: ['blue-snackbar']
          });

        }
      );
    }
    else {
      this.snackBar.open("Invalid form", "", { duration: 2000 });
    }
  }

}
