import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root'
})
export class CmsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  /*
  * This is the function which used to get user session
  */
  getToken = () => {
    var token = localStorage.getItem('token')
    return token == null ? '' : token;
  }


  getTermsConditions(pageNumber: any) {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_gettermsbyId}?page=${pageNumber}`, options);
  }

  getTermsConditionsById() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_gettermsbyId}`, options);
  }

  getPrivacyPolicy() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getprivacypolicy}`, options);
  }

  getPrivacyPolicyById() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getPPbyId}`, options);
  }

  getHelpCentersList() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getHelpCenters}`, options);
  }

  getHelpCentersById() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getHCbyId}`, options);
  }

  getCareerList() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getCareers}`, options);
  }

  getCareerById() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getCareerbyId}`, options);
  }

  getSocialMediaList() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getSocialMedia}`, options);
  }

  getSocialMediaById() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getSocialMediabyId}`, options);
  }

  getContactUsList() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getContactUs}`, options);
  }

  getContactUsById() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getContactUsbyId}`, options);
  }

  getFaqList() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getFaqs}`, options);
  }

  getFaqById() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getFaqbyId}`, options);
  }

  getBlogList() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getBlogs}`, options);
  }

  getBlogById() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getBlogbyId}`, options);
  }

  getEmailTempList() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getEmailtemps}`, options);
  }

  getEmailTempById(user_id: any) {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getEmailtempbyId}/${user_id}`, options);
  }

  getFooterList() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getFooters}`, options);
  }

  getFooterById(user_id: any) {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getFooterbyId}/${user_id}`, options);
  }

  getHeaderList() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getHeaders}`, options);
  }

  getHeaderById(user_id: any) {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getHeaderbyId}/${user_id}`, options);
  }

  getHomepageBanner() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getHomepagebanner}`, options);
  }

  getSocialLinks() {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.getToken()),
    }
    return this.httpClient.get(`${API.cms_url}/${API.menu_getSocialLinks}`, options);
  }

}
