<div class="appContainer">

  <div class="app-body">

    <main class="main">
      <div class="container-fluid">

        <div class="row">

          <div class="col-md-3" style="display: none;">
            <div class="item_filter_group accordionFilter">
              <h4>Filters</h4>
             
              <div [formGroup]="verticalSearchForm">
                <accordion>
           

                  <accordion-group [isOpen]="isFirstOpen">
                    <button class="btn btn-link shadow-none" accordion-heading type="button">
                      Status
                    </button>
                    <ng-select (change)="selectedPrice()" appearance="" [searchable]="false" [clearable]="false"
                      labelForId="status" formControlName="status">
                      <ng-option value="buyNow">
                        Buy Now
                      </ng-option>
                      <ng-option value="onAuction">
                        On Auction
                      </ng-option>
                      <ng-option value="hasOffer">
                        has Offers
                      </ng-option>
                    </ng-select>
               
                  </accordion-group>

                  <accordion-group>
                    <button class="btn btn-link shadow-none" accordion-heading type="button">
                      Item Type
                    </button>
                    <ng-select (change)="selectedItemType()" appearance="" [searchable]="false" [clearable]="false"
                      labelForId="itemType" formControlName="itemType">
                      <ng-option value="single">
                        Single Items
                      </ng-option>
                      <ng-option value="bundle">
                        Bundles
                      </ng-option>
                    </ng-select>
                 
                  </accordion-group>

                  <accordion-group>
                    <button class="btn btn-link shadow-none" accordion-heading type="button">
                      Price
                    </button>
                    <ng-select (change)="selectedCurrencyType()" appearance="" [searchable]="false" [clearable]="false"
                      labelForId="currencyType" formControlName="currencyType">
                      <ng-option value="eth">
                        BNB
                      </ng-option>
                      
                    </ng-select>
                  
                  </accordion-group>
                </accordion>
              </div>
            </div>
          </div>

          <div class="col-md-12">

            <div class="card-body-filter">
              <form [formGroup]="searchForm">
                <div class="row">
                  <div class="col-sm-3">
                    <ng-select (change)="selectedCatergory()" appearance="" [searchable]="false" [clearable]="false"
                      labelForId="categoryBy" formControlName="categoryBy">
                      <ng-option value="all">
                        All Categories
                      </ng-option>
                      <ng-option *ngFor="let item of categories" value="{{item._id}}">
                        <!-- <img src="../../../assets/images/{{item.coinValue}}-icon.svg" width="20px" height="20px" /> -->
                        {{item.title}}
                      </ng-option>
                   
                    </ng-select>
                  </div>
                  <div class="col-sm-3">
                    <ng-select (change)="selectedCollection()" appearance="" [searchable]="false" [clearable]="false"
                      labelForId="categoryBy" formControlName="collection_id">
                      <ng-option value="all">
                        All Collection
                      </ng-option>
                      <ng-option *ngFor="let item of collections" value="{{item._id}}">
                        {{item.name}}
                      </ng-option>
                    </ng-select>
                  </div>
           
                  <div class="col-sm-3">
                    <ng-select (change)="selectedPrice()" appearance="" [searchable]="false" [clearable]="false"
                      labelForId="price" formControlName="price">
                      <ng-option value="1">
                        > 1 {{configcurrency}}
                      </ng-option>
                      <ng-option value="10">
                        > 10 {{configcurrency}}
                      </ng-option>
                      <ng-option value="100">
                        > 100 {{configcurrency}}
                      </ng-option>
                      <ng-option value="1000">
                        > 1000 {{configcurrency}}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-sm-3">
                    <div>
                      <input type="text" class="form-control" formControlName="searchItemBy" id="name"
                        placeholder="Search" [(ngModel)]="searchInput">
                      <div class="card-body-filter-action">
                        <button type="submit" class="btn btn-primary" (click)="searchAction()"><i
                            class="fa fa-search"></i></button>
                      </div>

                    </div>
                  </div>
                 
                </div>
              </form>
            </div>



            <div class="collectionMainContainer" infiniteScroll [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50" (scrolled)="onNextPage()">
              <app-itemskeleton *ngIf="loading"></app-itemskeleton>
              <app-nodata *ngIf="!loading && items.length==0" msg="There is no data to display here"></app-nodata>
              <div class="row" *ngIf="!loading && items.length>0">
                <div class="col-md-4" *ngFor="let item of items">
                  <app-item [isUserIP]="isUser" [item]="item" (itemEvent)="itemEvent($event)"> </app-item>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </main>
  </div>
</div>

<app-loader *ngIf="spinner"></app-loader>