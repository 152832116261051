<section class="cms-sec">
    <div class="container">
    
    <h3>Terms Of Service</h3>
    
    <h4>Last Updated: July 13, 2022</h4>
    
    <h4>Introduction</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
            alteration in some form, by injected humour, or randomised words which don't look even slightly 
            believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
            anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
            tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
             It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
             to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
             from repetition, injected humour, or non-characteristic words etc.</p>
    
         <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
            alteration in some form, by injected humour, or randomised words which don't look even slightly 
            believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
            anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
            tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
             It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
             to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
             from repetition, injected humour, or non-characteristic words etc.</p>
    
       
    
        <h4>Accessing the Service</h4>
        
        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
            alteration in some form, by injected humour, or randomised words which don't look even slightly 
            believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
            anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
            tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
             It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
             to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
             from repetition, injected humour, or non-characteristic words etc.</p>
    
         <p>
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.
         </p>   
    
     <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>   
    
     <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p> 
        
    
        <h4>Ownership</h4>
    
    
        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
            alteration in some form, by injected humour, or randomised words which don't look even slightly 
            believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
            anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
            tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
             It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
             to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
             from repetition, injected humour, or non-characteristic words etc.</p>
    
    
    <h4>License to Access and Use Our Service and Content</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    
    <h4>Third-Party Content and Services</h4>
    
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    
    <h4>User Conduct</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
        <ul>
          
            <li>There are many variations of passages of Lorem Ipsum available</li>
            <li>There are many variations of passages of Lorem Ipsum available</li>
            <li>There are many variations of passages of Lorem Ipsum available</li>
            <li>There are many variations of passages of Lorem Ipsum available</li>
            <li>There are many variations of passages of Lorem Ipsum available</li>
            <li>There are many variations of passages of Lorem Ipsum available</li>
 
        </ul>   
    

    
        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
            alteration in some form, by injected humour, or randomised words which don't look even slightly 
            believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
            anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
            tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
             It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
             to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
             from repetition, injected humour, or non-characteristic words etc.</p>
    
     <h4>Intellectual Property Rights</h4>   
    
     <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
      
        <h4>Communication Preferences</h4>
    
        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
            alteration in some form, by injected humour, or randomised words which don't look even slightly 
            believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
            anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
            tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
             It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
             to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
             from repetition, injected humour, or non-characteristic words etc.</p>
    
    <h4>App Terms</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    <h4>Indemnification</h4>
    
    <p>By agreeing to these Terms and accessing the Service, you agree, to the fullest extent permitted by applicable law, to indemnify, defend, and hold harmless Art-to-nft, and our respective past, present, and future employees, officers,directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies,subsidiaries, affiliates, agents, representatives, predecessors, successors, and assigns (individually and collectively,the Art-to-nft Parties”), from and against all actual or alleged claims, damages, awards, judgments, losses, liabilities,obligations, penalties, interest, fees, expenses (including, without limitation, attorneys’ fees and expenses), andcosts (including, without limitation, court costs, costs of settlement, and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured,or suspected or unsuspected, in law or equity, whether in tort, contract, or otherwise (collectively, “Claims”),including, but not limited to, damages to property or personal injury, that are caused by, arise out of or are relatedto (a) your use or misuse of the Service, content, NFTs, or content linked to or associated with any NFTs (b) any Feedback you provide, (c) your violation or breach of any term of these Terms or applicable law, and (d) your violation of the rights of or obligations to a third party, including another user or third-party, and (e) your negligence or wilful misconduct. You agree to promptly notify Art-to-nft of any Claims and cooperate with the Art-to-nft Parties in
        defending such Claims. You further agree that the Art-to-nft Parties shall have control of the defense or settlement of any Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND Art-to-nft.</p>
    
    
     <h4>Disclaimers</h4>   
    
     <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>  
    
        <h4>Governing Law and Venue</h4>
    
        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
            alteration in some form, by injected humour, or randomised words which don't look even slightly 
            believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
            anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
            tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
             It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
             to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
             from repetition, injected humour, or non-characteristic words etc.</p>
    
    </div>
    </section>
    