/*
Project : Cryptotrades
FileName :  app.component.ts
Author : LinkWell
File Created : 21/07/2021
CopyRights : LinkWell
Purpose : This is the main component which load layout for the application
*/
import { AfterViewChecked, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CollapseDirective } from 'ngx-bootstrap/collapse';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserService } from './services/user.service';
import { config } from './constants/config';
import { ethers } from "ethers";
import Onboard from 'bnc-onboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Web3Service } from './services/web3.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Clipboard } from "@angular/cdk/clipboard";
import { async } from '@angular/core/testing';
import Web3 from 'web3';
import { Chain } from '@angular/compiler';
declare let window:any;

@Component({
  selector: 'modal-content',
  template: `
    <div class="modal-header">
      <h5 class="modal-title">SignIn with your Account</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <p>We detected you are using a different address other than saved to your Account</p>
      <div>
      <h4>Address saved to your Account</h4>
      <p style="color:red">please switch to this address in your wallet</p>
      <p (click)="copySavedAddress()">{{savedAddress}} &nbsp; <i class="fa fa-copy"></i></p>
      </div>
      <div>
      <h4>Your current connected address</h4>
      <p><strong>Connected</strong></p>
      <p (click)="copyCurrentAddress()">{{currentAddress}} &nbsp; <i class="fa fa-copy"></i></p>
      </div>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div> -->
  `
})

export class MaticAddressComponent implements OnInit {
  @Input() currentAddress: any;
  @Input() savedAddress: any;
  @Input() message: any;
  constructor(
    public activeModal: NgbActiveModal,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
  ) { }
  ngOnInit() {
    
  }

  copyCurrentAddress = () => {
    this.clipboard.copy(this.currentAddress);
    this.snackBar.open('Current Address copied', "", { duration: 2000,
          verticalPosition: 'top',
          panelClass: ['blue-snackbar'] });
  }

  copySavedAddress = () => {
    this.clipboard.copy(this.savedAddress);
    this.snackBar.open('Saved Address copied', "", { duration: 2000,
          verticalPosition: 'top',
          panelClass: ['blue-snackbar'] });
  }

}

@Component({
  selector: 'modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Test!</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <p>Please select Polygon Matic Testnet in Metamask</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `
})

export class ModalContentComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal
  ) { }
  ngOnInit() {
  }

}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewChecked {
  mediaBase: string = config.media_path
  title = 'nftfrontend';
  user: any = null;
  profile_image: string = ""
  current_page: string = '';
  isAPILoading: boolean = false;

  wallet: any = null;
  onboard: any = null;
  private _isCollapsed: boolean = true;
  set isCollapsed(value) {
    this._isCollapsed = value;
  }
  get isCollapsed() {
    if (this.collapseRef) {
      // temp fix for "overflow: hidden"
      if (getComputedStyle(this.collapseRef.nativeElement).getPropertyValue('display') === 'flex') {
        this.renderer.removeStyle(this.collapseRef.nativeElement, 'overflow');
      }
    }
    return this._isCollapsed;
  }
  @ViewChild(CollapseDirective, { read: ElementRef, static: false }) collapse !: CollapseDirective;
  collapseRef: any;

  wallets = [
    { walletName: "metamask", preferred: true },
  ];

  loginToken: any = null;
  first_name = localStorage.getItem('first_name');
  last_name = localStorage.getItem('last_name');
  id:any;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private userService: UserService,
    private snackBar: MatSnackBar,
    public web3Service: Web3Service,
    private ngbModal: NgbModal
  ) {
    localStorage.removeItem("token");
   // this.walletconnect();
    this.onboard = Onboard({
      dappId: config.onboard.key,       // [String] The API key created by step one above
      networkId: config.onboard.network,  // [Integer] The Ethereum network ID your Dapp uses.
      darkMode: true,
      blockPollingInterval: 4000,
      walletSelect: { wallets: this.wallets },
      subscriptions: {
        network: network => {
          if (network !== undefined && config.onboard.network != network) {
            this.logoutAction_network_failed();
            this.walletconnectcheck();
           // this.logoutAction_network_failed();
           // this.ngbModal.open(ModalContentComponent);
          }
        },
        address: address => {
          if (this.wallet.provider !== undefined) {
            this.web3Service.address = address;
            this.web3Service.web3Provider = new ethers.providers.Web3Provider(this.wallet.provider)
            this.web3Service.signer = this.web3Service.web3Provider.getSigner();
            if (address && address !== undefined) {
              localStorage.setItem("wallet", this.wallet.name)
              this.connectAPI(address);
            } else {
              this.logoutAction();
            }
          }
        },

       
      
        wallet: wallet => {
          this.connectWallet(wallet);
        }
        
      },
    });

    this.user = this.userService.getUser();
    this.loginToken = this.userService.getUserToken();
    if (this.loginToken != null) {
      if (this.loginToken.status == "reset") {
        this.router.navigate(['/reset']);
      }
    }
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      if (this.user != null) {
        if (this.user.first_name.length == 0) {
          this.router.navigate(['/settings/general']);
        }
      }
      var url = event.url.split("/");
      if (url[1].includes("?")) {
        var suburl = url[1].split("?")
        this.current_page = suburl[0];
      } else {
        this.current_page = url[1];
      }
    });
  }

  ngOnInit () {
    if (this.user != null) {
      this.profile_image = this.user.profile_image != '' ? this.user.profile_image : "nouser.jpg"
      if (this.user.first_name.length == 0) {
        this.router.navigate(['/settings/general']);
      }
    }
    
    // if(localStorage.getItem("wallet")){
    //   this.id = setInterval(() => {
    //     this.logoutAction_network_failed();
    //     this.walletconnectcheck();
    //   }, 2000);
    // }
    
    
    this.walletSelect();
  //   setTimeout(async () => {
  //     alert("fgf");
  //     this.walletconnect();
  //  }, 2000);

  }

  onRightClick() {
    return false;
}

  // ngOnDestroy() {
  //   if (this.id) {
  //     clearInterval(this.id);
  //   }
  // }

  walletSelect = async () => {
    if (localStorage.getItem("wallet")) {
      this.isAPILoading = true
      let res = await this.onboard.walletSelect(localStorage.getItem("wallet"));
      await this.onboard.walletCheck()
    }
  }


  ngAfterViewChecked(): void {
    this.collapseRef = this.collapse;
  }

  /**
   * This is the function which used to logout user from the application on menu click
   */
  logoutAction = async () => {
    localStorage.removeItem("wallet");
    localStorage.removeItem("token");
    localStorage.clear();
    sessionStorage.clear();
    await this.onboard.walletReset();
    location.href = config.base_url
  }

  logoutAction_network_failed = async () => {
    localStorage.removeItem("wallet");
    localStorage.removeItem("token");
    await this.onboard.walletReset();
    // location.href = config.base_url
  }

  /**
   * This is the function which used to login through metamask
   */
  connectWalletAction = async () => {
    this.isAPILoading = true
    await this.onboard.walletReset();
    await this.onboard.walletSelect("MetaMask");
    await this.onboard.walletCheck();
  }

 
  async walletconnectcheck()  {
    window.web3 = new Web3(window.web3.currentProvider);
    const defaultAccount = await window.ethereum.enable();

    if (defaultAccount.length > 0) {
      let usr_wallet = defaultAccount[0].toLowerCase();
      window.web3.eth.net.getId(async (err:any, chainId:any) => {
        if (chainId !== config.onboard.network) {
          if(window.ethereum){
            try{
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: config.onboard.networkid }],
              });
              
              this.connectWalletAction();
        
            }catch(error:any){
              if(error.code==4001){
               
              }else{
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: config.onboard.networkid,
                      chainName: config.blockchain,
                      nativeCurrency: {
                        name: config.onboard.curreency,
                        symbol: config.onboard.curreency, // 2-6 characters long
                        decimals: config.onboard.decimal
                      },
                     rpcUrls: [config.onboard.rpc],
                     blockExplorerUrls: [config.onboard.blockurl],
                    },
                  ],
                });
                this.connectWalletAction();
             
              }
              
            }
          }
          
        } else {
          

        }
      })

    }
  }

  connectWallet = async (wallet: any) => {
    if (wallet && wallet !== undefined) {
      //localStorage.setItem("wallet",wallet.name)
      this.wallet = wallet;
    }
  }

  connectAPI = (address: any) => {
    var params = {
      username: `${this.loginToken['username']}`,
      first_name: `${this.loginToken['first_name']}`,
      last_name: `${this.loginToken['last_name']}`,
      email: `${this.loginToken['email']}`,
      profile_image: '',
      social_info: JSON.stringify({ "id": address, "type": "metamask" })
    }
    this.userService.register(params).subscribe(
      (result: any) => {
        this.isAPILoading = false;
        if (result.status == true) {
          // localStorage.setItem('token', result.return_id);
          localStorage.setItem('token', result.token);
          this.user = this.userService.getUser();
          localStorage.setItem('username', this.user.username);
          localStorage.setItem('userid', this.user.user_id);
          localStorage.setItem('address',address);
          this.userService.profileNotification({
            type: 'login',
            data: this.user
          })
          if (this.user.first_name.length == 0) {
            this.router.navigate(['/settings/general']);
          }
          this.profile_image = this.user.profile_image != '' ? this.user.profile_image : "nouser.jpg"
        }else if(result.status == false){
          this.snackBar.open(result.message, "", { duration: 2000,
            verticalPosition: 'top',
            panelClass: ['red-snackbar'] });
        } else {
          // this.snackBar.open(result.message, "", { duration: 2000 });
          let modalRef = this.ngbModal.open(MaticAddressComponent, {
            size: "md",
          });
          modalRef.componentInstance.savedAddress = result.savedAddressInfo.id;
          modalRef.componentInstance.currentAddress = result.currentAddress;
          modalRef.componentInstance.message = result.message;
        }
      }
    )
  }
}
