/*
Project : Cryptotrades
FileName :  home.component.ts
Author : LinkWell
File Created : 21/07/2021
CopyRights : LinkWell
Purpose : This is the component which show home screen for user
*/
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router'
import { CollectionService } from 'src/app/services/collection.service';
import { ItemService } from 'src/app/services/item.service';
import { config } from 'src/app/constants/config';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CmsService } from 'src/app/services/cms.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  mediaBase: string = config.media_path
  configcurrency: string = config.onboard.curreency;
  currentUser: any;
  collectionMenu: string = "";
  collections: any = [];
  collectionItems: any = [];
  loading: boolean = true;
  categories: any = []
  topsellers: any = [];
  hotcollections: any = [];
  newitems: any = [];
  spinner: boolean = false;
  homepageBannersInfo: any;
  isUser = false;
  constructor(
    private userService: UserService,
    private router: Router,
    private collectionService: CollectionService,
    private itemService: ItemService,
    private cmsService: CmsService
  ) {
    this.currentUser = this.userService.getUser();
    this.getCollection();
    this.getCategory();
    this.getTopsellers();
    this.getNewitems();
    this.gethotcollections();
    // this.getHomepageBanner();
  }

  ngOnInit(): void {
  }

  getHomepageBanner() {
    this.cmsService.getHomepageBanner().subscribe(
      (res: any) => {
        if (res.status == true) {
          this.homepageBannersInfo = res.result[0];
        }
      }
    )
  }

  /**
   * This is the function which navigate uswer to login and marketplace on button click
   */
  openCreate = () => {
    if (this.currentUser == null) {
      this.router.navigate(["/login"]);
    } else {
      this.router.navigate(["/collection/mycollection"]);
    }
  }

  /**
   * This is the function which load item by collection on tab click
   */
  getCollection = () => {
    this.spinner = true;
    var params = {
      page: 1,
      type: 'item',
    }
    this.collectionService.listCollection(params).subscribe(result => {
      if (result.status == true) {
        for (let index = 0; index < result.tempArray.length; index++) {
          const element = result.tempArray[index];
          this.collections.push(element);
          if (index == 6) {
            break;
          }
        }
        this.showItemByCollection(this.collectionMenu)
      }
    })
    setTimeout(() => {
      this.spinner = false;
    }, 1000);
  }

  /**
   * This is the function which load item by collection on tab click
   */
  showItemByCollection = (id: any) => {
    this.spinner = true;
    this.collectionMenu = id;
    var params = {}
    if (this.collectionMenu == "") {
      params = {
        page: 1,
        type: "recent",
        keyword: ""
      }
    } else {
      params = {
        page: 1,
        type: "collection",
        collection_id: this.collectionMenu,
        keyword: ""
      }
    }

    this.itemService.listItem(params).subscribe(result => {
      if (result.status == true) {
        this.collectionItems = [];
        for (let index = 0; index < result.tempArray.length; index++) {
          const element = result.tempArray[index];
          this.collectionItems.push(element)
          if (index == 5) {
            break;
          }
        }
      }
      setTimeout(() => {
        this.spinner = false;
      }, 1000);
    })
  }

  /**
   * This is the output function which used to navigate user to item detail page
   */
  itemEvent = (event: any) => {
    if (event.type == "view") {
      this.router.navigate(['/item/view/' + event.item._id])
    }
  }

  /**
   * This is the function which used to get category list from api
   */
  getCategory = () => {
    this.spinner = true;
    this.itemService.categoryList().subscribe(result => {
      for (let index = 0; index < result.tempArray.length; index++) {
        const element = result.tempArray[index];
        this.categories.push(element);
        if (index == 7) {
          break;
        }
      }
    })
    setTimeout(() => {
      this.spinner = false;
    }, 1000);
  }

  getTopsellers = () => {
    this.spinner = true;
    this.itemService.topsellerList().subscribe(result => {
      this.topsellers = result.data
    })
    setTimeout(() => {
      this.spinner = false;
    }, 1000);
  }

  getNewitems = () => {
    this.spinner = true;
    this.itemService.newitemList().subscribe(result => {
      this.newitems = result.data
    })
    setTimeout(() => {
      this.spinner = false;
    }, 1000);
  }

  gethotcollections = () => {
    this.spinner = true;
    this.itemService.hotcollectionList().subscribe(result => {
      this.hotcollections = result.data
    })
    setTimeout(() => {
      this.spinner = false;
    }, 1000);
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: true
  }
}