import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { config } from 'src/app/constants/config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  EyeFlag: boolean = false;
  confirmEyeFlag: boolean = false;
  loginToken: any;
  spinner: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private snackBar: MatSnackBar,
  ) {
    this.loginForm = this.formBuilder.group(
      {
        username: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required, Validators.minLength(6)])
      }
    );

  }

  ngOnInit(): void {
  }

  onEyeClick() {
    this.EyeFlag = !this.EyeFlag;
  }

  onLoginSubmit() {
    
    if (this.loginForm.valid) {
      this.spinner = true;
      this.userService.loginUser(this.loginForm.value).subscribe(
        (res: any) => {
          if (res.status == true) {
            this.spinner = false;
            this.snackBar.open(res.message, "", { duration: 2000,
          verticalPosition: 'top',
          panelClass: ['blue-snackbar'] });
            localStorage.setItem('login_token', res.token);
            this.loginToken = this.userService.getUserToken();
            localStorage.setItem('first_name', this.loginToken.first_name);
            localStorage.setItem('last_name', this.loginToken.last_name);
            localStorage.setItem('userid', this.loginToken.user_id);
            location.href = config.base_url
          }
          if (res.status == false) {
            this.spinner = false;
            this.snackBar.open(res.message, "", { duration: 2000,
          verticalPosition: 'top',
          panelClass: ['blue-snackbar'] });
          }
        },
        (error: any) => {
          this.spinner = false;
          this.snackBar.open("Uploaded file should be JPEG/PNG", "", { duration: 2000,
          verticalPosition: 'top',
          panelClass: ['blue-snackbar'] });

        }
      );
    }
    else {
      this.snackBar.open("Invalid form", "", { duration: 2000 });
    }
  }

}
