<div class="edit_profile register login">
    <div class="container">
        <div class="row  align-items-center">

            <div class="col-lg-5 logtxt">
                <h2>Create, sell or collect digital items.</h2>
            </div>

            <div class="col-lg-4 offset-lg-2">
                <div class="right_part space-y-20">
                    <h1>Reset Password</h1>
                    <!-- <h3>Login using an existing account or create a
                        <a [routerLink]="['/register']" routerLinkActive="router-link-active">
                            new Account here </a>
                    </h3> -->
                    <div class="box edit_box w-full space-y-20">
                        <form [formGroup]="resetForm" (ngSubmit)="onResetSubmit()">
                            <div class="space-y-10 mt-3">
                                <span class="nameInput">New Password</span>
                                <div class="confirm">
                                    <input [type]="EyeFlag ? 'text' : 'password'" class="form-control"
                                        formControlName="newpassword">
                                    <span class="icr_eye">
                                        <i class="fa fa-eye" [ngClass]="{
                                        'fa-eye-slash': !EyeFlag,
                                        'fa-eye': EyeFlag
                                      }" (click)="onEyeClick()"></i>
                                    </span>
                                    <div *ngIf="resetForm.get('newpassword')?.touched && resetForm.get('password')?.invalid"
                                        class="text text-danger">
                                        <div *ngIf="resetForm.get('newpassword')?.getError('required')">
                                            Password is required.
                                        </div>
                                        <div *ngIf="resetForm.get('newpassword')?.getError('minlength')">
                                            Password minimum length is 6.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="space-y-10 mt-3">
                                <span class="nameInput">Confirm Password</span>
                                <div class="confirm">
                                    <input [type]="confirmEyeFlag? 'text' : 'password'" class="form-control"
                                        formControlName="confirmPassword">
                                    <!-- <span class="eye_icc">
                                    <i class="fa fa-eye"></i>
                                </span> -->
                                    <span class="icr_eye">
                                        <i class="fa fa-eye" [ngClass]="{
                            'fa-eye-slash': !confirmEyeFlag,
                            'fa-eye': confirmEyeFlag
                          }" (click)="onConfirmEyeClick()"></i>
                                    </span>
                                    <div *ngIf="resetForm.get('confirmPassword')?.touched && resetForm.get('confirmPassword')?.invalid"
                                        class="text text-danger">
                                        <div *ngIf="resetForm.get('confirmPassword')?.getError('required')">
                                            Confirm Password is required.
                                        </div>
                                        <div *ngIf="resetForm.get('confirmPassword')?.getError('mustMatch')">
                                            Passwords
                                            must
                                            match</div>
                                    </div>

                                </div>
                            </div>
                            <div class="space-y-10 mt-3">
                                <button type="submit" class="btn btn-grad  btn-lg " [disabled]="!resetForm.valid">
                                    Reset</button>
                            </div>

                            <!-- <div class="space-y-10 mt-3">
                                <button class="forgot" variant="primary">Forgot Password</button>
                            </div> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loader *ngIf="spinner"></app-loader>