export const config = {
    site_name: "ART TO NFT",
    media_path: 'https://api.art-to-nft.io',
    base_url: 'https://www.art-to-nft.io',
    google_client_id: '606358659043-cmnk9qoe8nl80sift89bmbvdsgv9blnu.apps.googleusercontent.com',
    facebook_client_id: '370223044026040',
    blockchain: "ART TO NFT",
    blockchain_url: "https://polygonscan.com/",
    onboard: {
        key: 'b9c4f15a-de2e-440b-97a0-7925abab9353',
        network: 137,
        networkid: '0x89',
        curreency: 'MATIC',
        decimal: 18,
        rpc: 'https://polygon-rpc.com',
        blockurl: 'https://polygonscan.com/'
    },
    "main_address": "0x67a4e16046dD6dBd2c0055dCDbBcbE1c4324f452",
    "multi_contract_address": "0x57fDB2C50D48b485A0e3D254b35b47050F856126"
}
