/*
Project : Cryptotrades
FileName :  marketplace.component.ts
Author : LinkWell
File Created : 21/07/2021
CopyRights : LinkWell
Purpose : This is the component file which used to show marketplace screen for user
*/
import { Component, OnInit } from '@angular/core';
import { config } from 'src/app/constants/config';
import { CollectionService } from 'src/app/services/collection.service';
import { ItemService } from 'src/app/services/item.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { getBsVer, IBsVersion } from 'ngx-bootstrap/utils';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.css']
})
export class MarketplaceComponent implements OnInit {

  isFirstOpen = true;
  

  get _getBsVer(): IBsVersion {
    return getBsVer();
  }

  people = [];
  selectedCategories = [];

  subscription: any;
  isToggled: boolean = false;
  isOfferCollapsed: boolean = false;
  isPriceCollapsed: boolean = true;
  isCollectionCollapsed: boolean = true;
  isCategoryCollapsed: boolean = true;
  spinner: boolean = false;

  collectionSearchInput: string = ""
  searchRequest: any;
  collectionPage: any = 1;
  collections: any = [];
  mediaBase: string = config.media_path;
  configcurrency: string = config.onboard.curreency;
  categories: any = [];

  searchInput: string = ""
  items: any = [];
  page: any = 1;
  isApiLoading: boolean = false;
  loading: boolean = true
  keyword: string = ""
  type: string = "sort"
  value: string = "recent"
  page_title: string = "Recent Products"

  public timestamp = Math.floor(new Date().getTime() / 1000.0);
  isUser = false;
  searchForm: FormGroup;
  verticalSearchForm: FormGroup;
  categories_dummy: any = [
    {
      category_image: "art.png",
      status: "active",
      title: "ART1"
    },
    {
      category_image: "art.png",
      status: "active",
      title: "ART2"
    }
  ];
  constructor(
    private collectionService: CollectionService,
    private itemService: ItemService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
  ) {
    this.searchForm = this.formBuilder.group({
      categoryBy: ['all'],
      collection_id: ['all'],
      sortBy: ['all'],
      price: ['1'],
      searchItemBy: ['']
    });
    this.verticalSearchForm = this.formBuilder.group({
      categoryBy: [''],
      status: ['buyNow'],
      itemType: ['single'],
      currencyType: ['eth'],
      // searchItemBy: ['']
    });
    this.searchCollectionAction();
    this.getCategories()
  }

  ngOnInit(): void {
    this.spinner = true;
    this.subscription = this.route.queryParams.subscribe(params => {
      this.loading = true;
      if (params.type) {
        this.type = "sort";
        this.value = params.type
        if (params.type == "offer") {
          this.page_title = "Product with offer"
        } else if (params.type == "mostviewed") {
          this.page_title = "Most viewed product"
        } else if (params.type == "mostliked") {
          this.page_title = "Most liked product"
        } else {
          this.page_title = "Recent product"
        }
      }
      if (params.price) {
        this.type = "price";
        this.value = params.price
        this.page_title = "Products greater than " + this.value + " " + this.configcurrency
      }
      if (params.category_id) {
        this.type = "category";
        this.value = params.category_id

      }
      if (params.collection_id) {
        this.type = "collection";
        this.value = params.collection_id
        this.getCollectionInfo()
      }

      this.searchInput = "";
      this.items = [];
      this.page = 1;
      this.keyword = ""
      this.getItemList();
    });
    setTimeout(() => {
      this.spinner = false;
    }, 1000);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  /**
   * This is the function which used minimize and maximize sidebar 
   */
  minimizeMenu = () => {
    this.isToggled = this.isToggled ? false : true;
  }

  /**
   * This is the function which used search collection on textbox type
   */
  searchCollectionAction = () => {
    if (this.searchRequest) {
      this.searchRequest.unsubscribe();
    }
    this.collectionPage = 1;
    this.searchRequest = this.collectionService.listCollection({
      type: "all",
      keyword: this.collectionSearchInput,
      page: this.collectionPage
    }).subscribe(result => {
      if (result.status == true) {
        this.collections = result.tempArray
      }
    })
  }

  /**
   * This is the function which used to retreive category list from api
   */
  getCategories = () => {
    this.itemService.categoryList().subscribe(result => {
      if (result.status == true) {
        this.categories = result.tempArray
        if (this.type == "category") {
          var index = this.categories.findIndex((category: any) => {
            return category._id == this.value
          })
          if (index != -1) {
            this.page_title = "Search product by category - " + this.categories[index].title
          }
        }
      }
    })
  }

  /**
   * This is the function which used to retreive item list from api
   */
  getItemList = () => {
    this.isApiLoading = true;
    //this.items = [];
    var params = {}
    if (this.type == "sort") {
      params = {
        page: this.page,
        type: this.value,
        keyword: this.keyword
      }
    } else if (this.type == "collection") {
      params = {
        page: this.page,
        type: "collection",
        collection_id: this.value,
        keyword: this.keyword
      }
    } else if (this.type == "category") {
      params = {
        page: this.page,
        type: "category",
        category_id: this.value,
        keyword: this.keyword
      }
    } else if (this.type == "price") {
      params = {
        page: this.page,
        type: "price",
        price_range: this.value,
        keyword: this.keyword
      }
    }

    this.itemService.listItem(params).subscribe(result => {
      this.isApiLoading = false;
      if (result.status == true) {
    
        for (let index = 0; index < result.tempArray.length; index++) {
          const element = result.tempArray[index];
          if (element.enableAuction == true) {
            let startDate = new Date(`${element.startDateTimeUtc}`);
            let startTimestamp = Math.floor(startDate.getTime() / 1000);
            let endDate = new Date(`${element.endDateTimeUtc}`);
            let endTimestamp = Math.floor(endDate.getTime() / 1000);

            if (this.timestamp >= startTimestamp && this.timestamp <= endTimestamp) {
              let obj = {
                create_date: element.create_date,
                description: element.description,
                // endDateTime: element.endDateTime,
                // endDateTimeUtc: endTimestamp,
                like_count: element.like_count,
                name: element.name,
                price: element.price,
                // startDateTime: element.startDateTime,
                // startDateTimeUtc: startTimestamp,
                status: element.status,
                thumb: element.thumb,
                view_count: element.view_count,
                _id: element._id,
                enableAuction: element.enableAuction,
                current_owner: element.current_owner,
                enableTimestamp: true,
                difference: endTimestamp - this.timestamp
              }
              this.items.push(obj)
            }
            else {
              this.items.push(element);
            }
            
          }
          else {
            this.items.push(element);
           
          }
        }
      }else if(!result.status && result.message=='Token is not valid'){
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/login']).then(() => {
          this.snackBar.open("Session Expired", "", { duration: 2000,
            verticalPosition: 'top',
            panelClass: ['blue-snackbar'] });
          window.location.reload();
        });
      }
      this.loading = false;
    })
  }


  /**
   * This is the function which used to retreive collection info on sorting
   */
  getCollectionInfo() {
    this.collectionService.viewCollection({
      collection_id: this.value
    }).subscribe(result => {
      this.page_title = "Search product by collection - " + result.result.name
    })
  }


  /**
  * This is the function which used to retreive item list on page scroll
  */
  onNextPage = () => {
    if (this.loading == true && this.isApiLoading == true) {
      return;
    }
    this.page = this.page + 1;
    this.getItemList();
  }

  /**
   * This is the output function which used to navigate user to item detail page
   */
  
  itemEvent = (event: any) => {
    if (event.type == "view") {
      this.router.navigate(['/item/view/' + event.item._id])
    }
  }


  /**
   * This is the function triggered when user click search button
   */
  searchAction = () => {
    this.items = [];
    this.loading = true;
    this.keyword = this.searchInput.replace(" ", "+")
    this.page = 1;
    this.items = [];
    this.getItemList();
  }

  searchEnterAction = () => {
    this.items = [];
  }

  /**
   * This is the function triggered when user click reset button
   */
  resetAction = () => {
    this.loading = true;
    this.searchInput = "";
    this.keyword = "";
    this.searchAction();
  }

  /**
   * This is the function triggered when user filter the item by category
   */
  categoryAction = (item: any) => {
    var index = this.categories.findIndex((category: any) => {
      return category._id == item._id
    })
    if (index != -1) {
      this.page_title = "Search product by category - " + this.categories[index].title
    }
  }

  selectedCatergory() {
    let category_id = this.searchForm.get('categoryBy')?.value;
    if (category_id == 'all') {
      this.router.navigate(['/marketplace'], { queryParams: { type: 'recent' } });
    } else {
      this.router.navigate(['/marketplace'], { queryParams: { category_id: category_id } });
    }
  }

  selectedCollection() {
    let collection_id = this.searchForm.get('collection_id')?.value;
    if (collection_id == 'all') {
      this.router.navigate(['/marketplace'], { queryParams: { type: 'recent' } });
    } else {
      this.router.navigate(['/marketplace'], { queryParams: { collection_id: collection_id } });
    }
  }

  selectedSortBy() {
    let sortBy = this.searchForm.get('sortBy')?.value;
    this.router.navigate(['/marketplace'], { queryParams: { type: sortBy } });
  }

  selectedPrice() {
    let price = this.searchForm.get('price')?.value;
    this.router.navigate(['/marketplace'], { queryParams: { price: price } });
  }

  selectedStatus() {
    let status = this.verticalSearchForm.get('status')?.value;
    // this.router.navigate(['/marketplace'], { queryParams: { price: price } });
  }

  selectedItemType() {
    let itemType = this.verticalSearchForm.get('itemType')?.value;
    // this.router.navigate(['/marketplace'], { queryParams: { price: price } });
  }

  selectedCurrencyType() {
    let currencyType = this.verticalSearchForm.get('currencyType')?.value;
    // this.router.navigate(['/marketplace'], { queryParams: { price: price } });
  }

}
