<div class="edit_profile register login">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="right_part space-y-20">
                    <h1 class="color_white"> Register</h1>
                    <h3>Have an account ?
                        <a  [routerLink]="['/login']" routerLinkActive="router-link-active">
                            Login </a>
                        </h3>

                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque 
                            laudantium, totam rem aperiam, eaque ipsa quae ab 
                            illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>

                    <div class="box edit_box w-full space-y-20">
                        <form [formGroup]="registerForm" (ngSubmit)="onRegisterSubmit()">
                           
                           <div class="row">
                            <div class="col-md-6 mb-3">
                                <span class="nameInput">UserName</span>
                                <div class="confirm ">
                                    <!-- style="background: #1c095f !important;" -->
                                    <input type="text" class="form-control" formControlName="username">
                                    <div *ngIf="registerForm.get('username')?.touched" class="text text-danger">
                                        <div *ngIf="registerForm.get('username')?.getError('required')">
                                            UserName is required.
                                        </div>
                                        <div *ngIf="registerForm.get('username')?.getError('pattern')">
                                            UserName must be alphabetic.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3"> 
                                <span class="nameInput">FirstName</span>
                                <div class="confirm">
                                    <!-- style="background: #1c095f !important;" -->
                                    <input type="text" class="form-control" formControlName="first_name">
                                    <div *ngIf="registerForm.get('first_name')?.touched" class="text text-danger">
                                        <div *ngIf="registerForm.get('first_name')?.getError('required')">
                                            FirstName is required.
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <span class="nameInput">LastName</span>
                                <div class="confirm">
                                    <!--  -->
                                    <input type="text" class="form-control" formControlName="last_name">
                                    <div *ngIf="registerForm.get('last_name')?.touched" class="text text-danger">
                                        <div *ngIf="registerForm.get('last_name')?.getError('required')">
                                            LastName is required.
                                        </div>
                                                                          </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <span class="nameInput">Email </span>
                                <div class="confirm form-group">
                                    <input type="text" class="form-control" formControlName="email"
                                        placeholder="Enter email">
                                    <div *ngIf="registerForm.get('email')?.touched" class="text text-danger">
                                        <div *ngIf="registerForm.get('email')?.getError('required')">
                                            Email is required.
                                        </div>
                                        <div *ngIf="registerForm.get('email')?.getError('email')">
                                            Email is not Valid.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-md-6 mb-3">
                                <span class="nameInput">Password</span>
                                <div class="confirm">
                                    <input [type]="EyeFlag ? 'text' : 'password'" class="form-control"
                                        formControlName="password">
                                    <span class="icr_eye">
                                        <i class="fa fa-eye" [ngClass]="{
                                            'fa-eye-slash': !EyeFlag,
                                            'fa-eye': EyeFlag
                                          }" (click)="onEyeClick()"></i>
                                    </span>
                                    <div *ngIf="registerForm.get('password')?.touched && registerForm.get('password')?.invalid"
                                        class="text text-danger">
                                        <div *ngIf="registerForm.get('password')?.getError('required')">
                                            Password is required.
                                        </div>
                                        <div *ngIf="registerForm.get('password')?.getError('minlength')">
                                            Password minimum length is 6.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <span class="nameInput">Confirm Password</span>
                                <div class="confirm">
                                    <input [type]="confirmEyeFlag? 'text' : 'password'" class="form-control"
                                        formControlName="confirmPassword">
                                    <span class="icr_eye">
                                        <i class="fa fa-eye" [ngClass]="{
                                'fa-eye-slash': !confirmEyeFlag,
                                'fa-eye': confirmEyeFlag
                              }" (click)="onConfirmEyeClick()"></i>
                                    </span>
                                    <div *ngIf="registerForm.get('confirmPassword')?.touched && registerForm.get('confirmPassword')?.invalid"
                                        class="text text-danger">
                                        <div *ngIf="registerForm.get('confirmPassword')?.getError('required')">
                                            Confirm Password is required.
                                        </div>
                                        <div *ngIf="registerForm.get('confirmPassword')?.getError('mustMatch')">
                                            Passwords
                                            must
                                            match</div>
                                    </div>

                                </div>
                            </div>
                            </div>
                            <div class="space-y-10 mt-3">
                                <button type="submit" class="btn btn-grad btn-lg"
                                    [disabled]="!registerForm.valid"> Register Now</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loader *ngIf="spinner"></app-loader>