<section class="cms-sec">
    <div class="container">
        
        <h3>Privacy Policy</h3>
    
    <h4>Last Updated: July 13, 2022</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    
    <h4>Types of Data We Collect</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    
     <h4>Information You Provide Us</h4>   
    
     <ul>
        <li>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
            alteration in some form,</li>
        <li>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
             alteration in some form</li>

            </ul>
    
    <h4>Information Collected via Technology.</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    
        <h4>Information Collected from Third-Party Companies.</h4> 
    
        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
            alteration in some form, by injected humour, or randomised words which don't look even slightly 
            believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
            anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
            tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
             It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
             to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
             from repetition, injected humour, or non-characteristic words etc.</p>
    
    <h4>Public Information Observed from Blockchains</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    <h4>Use of Your Personal Data
        </h4>    
        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
            alteration in some form, by injected humour, or randomised words which don't look even slightly 
            believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
            anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
            tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
             It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
             to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
             from repetition, injected humour, or non-characteristic words etc.</p>
    
       
    
    
    <h4>Disclosure of Your Personal Data.</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    <h4>Third Party Service Providers</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    <h4>Affiliates</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    <h4>Corporate Restructuring</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    
     <h4>Legal Rights </h4>   
    
     <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    <h4>Other Disclosures</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    
    <h4>Third-Party Websites</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    
     <h4>Third-Party Wallets</h4>   
    
     <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    <h4>Data Protection</h4>
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    
    <h4>Minors</h4>    
    
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
        alteration in some form, by injected humour, or randomised words which don't look even slightly 
        believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
        tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
         It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
         to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
         from repetition, injected humour, or non-characteristic words etc.</p>
    
    
    </div>
    </section>
    