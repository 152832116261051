

<div class="edit_profile register login">
    <div class="container">
        <div class="row  align-items-center">

        <div class="col-lg-5 logtxt">
            <h2>Create, sell or collect digital items.</h2>
        </div>

            <div class="col-lg-4 offset-lg-2">
                <div class="right_part space-y-20">
                    <h1> Sign In</h1>
                    <h3>Login using an existing account or create a  
                        <a [routerLink]="['/register']" routerLinkActive="router-link-active">
                            new Account here </a>
                        </h3>
                    <div class="box edit_box w-full space-y-20">
                        <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
                            <div class="mb-3">
                                <div class="confirm">
                                    <input type="text" class="form-control" formControlName="username" placeholder="Email">
                                    <div *ngIf="loginForm.get('username')?.touched" class="text text-danger">
                                        <div *ngIf="loginForm.get('username')?.getError('required')">
                                            UserName is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="space-y-10">
                                <div class="confirm">
                                    <input [type]="EyeFlag ? 'text' : 'password'" class="form-control"
                                        formControlName="password" placeholder="Password">
                                    <span class="icr_eye">
                                        <i class="fa fa-eye" [ngClass]="{
                                            'fa-eye-slash': !EyeFlag,
                                            'fa-eye': EyeFlag
                                          }" (click)="onEyeClick()"></i>
                                    </span>
                                    <div *ngIf="loginForm.get('password')?.touched && loginForm.get('password')?.invalid"
                                        class="text text-danger">
                                        <div *ngIf="loginForm.get('password')?.getError('required')">
                                            Password is required.
                                        </div>
                                        <div *ngIf="loginForm.get('password')?.getError('minlength')">
                                            Password minimum length is 6.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="space-y-10 mt-3">
                                <button type="submit" class="btn btn-grad  btn-lg "
                                    [disabled]="!loginForm.valid"> Submit</button>
                            </div>


                            <div class="space-y-10 mt-3">
                                <button class="forgot" variant="primary" routerLink="/forgot">Forgot Password</button>
                            </div>


                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loader *ngIf="spinner"></app-loader>
