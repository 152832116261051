/*
Project : Cryptotrades
FileName :  item.component.ts
Author : LinkWell
File Created : 21/07/2021
CopyRights : LinkWell
Purpose : This is the component file which used to define item as sub compoonent
*/
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { config } from 'src/app/constants/config';
import { ItemService } from 'src/app/services/item.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {
  mediaBase: string = config.media_path
  configcurrency: string = config.onboard.curreency;
  @Input() item: any;
  @Output() itemEvent = new EventEmitter<any>();
  @Input() isUserIP: any;
  @Input() isLoginUserDetail: any;
  isUser: any;
  loginToken: any;
  constructor(
    private itemService: ItemService,
    private snackBar: MatSnackBar,
    private router: Router, private userService: UserService
  ) {
    this.isUser = localStorage.getItem('isUser');
  }

  ngOnInit(): void {
    this.loginToken = this.userService.getUserToken();

  }

  /**
   * This is the function which used send notification to parent component for item view
   */
  viewAction = () => {
    this.itemEvent.emit({
      type: "view",
      item: this.item
    });
  }


  /**
   * This is the function which used send notification to parent component for item edit
   */
  editAction = () => {
    this.itemEvent.emit({
      type: "edit",
      item: this.item
    });
  }

  /**
   * This is the function which used send notification to parent component for item delete
   */
  deleteAction = () => {
    this.itemEvent.emit({
      type: "delete",
      item: this.item
    });
  }

  /**
   * This is the function which used send notification to parent component for item publish
   */
  publishAction = () => {
    this.itemEvent.emit({
      type: "publish",
      item: this.item
    });
  }

  handleEvent($event: any, item: any) {
    if ($event.action == 'done') {
      let obj = {
        item_id: item._id
      }
      this.itemService.delistNft(obj).subscribe(
        (res: any) => {
          this.router.navigate(['/']);
          // this.snackBar.open(res.message, "", { duration: 2000, verticalPosition: 'top' });
        }
      )
    }

  }

  /**
 * This is the function which used send notification to parent component for sending to market place and here we are sending total item
 */
  listNftAction = () => {
    this.itemEvent.emit({
      type: "list_nft",
      item: this.item
    });
  }

  /**
* This is the function which used send notification to parent component for removing from market place
*/
  delistNftAction = () => {
    this.itemEvent.emit({
      type: "delist_nft",
      item: this.item
    });
  }

  /**
   * This is the function which used to edit minted item
   */
  editMint = () => {
    this.itemEvent.emit({
      type: "edit_mint",
      item: this.item
    });
  }

}
