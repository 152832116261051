<footer class="footer__1">
  <div class="container">
    <div class="row">

      <div class="col-md-4 col-sm-6 col-xs-1">
        <h6 class="footer__title">Marketplace</h6>
        <ul class="footer__list">
          <li> <a routerLink="/marketplace"> All NFTs </a></li>

          <ng-container *ngFor="let category of categories">
            <li> <a [routerLink]="['/marketplace']"
              [queryParams]="{category_id: category._id}"> {{category.title}} </a></li>
          </ng-container>
          
        </ul>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-1">
        <ng-container *ngIf="user">
        <h6 class="footer__title">My Account</h6>
        <ul class="footer__list">
          <li *ngIf="user"> <a [routerLink]="'/profile/'+user+'/collected'"> My NFTs </a>
          </li>
          <li *ngIf="user"> <a [routerLink]="'/profile/'+user+'/favorited'"> My Favourites </a>
          </li>
          <li> <a [routerLink]="'/collection/mycollection'"> My Collections </a>
          </li>
          <li *ngIf="user"> <a [routerLink]="'/profile/'+user+'/activity'"> Activity
            </a> </li>
        </ul>
      </ng-container>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-1">
        <h6 class="footer__title">Resources</h6>
        <ul class="footer__list">
          <li><a routerLink="/terms">Terms & Conditions</a></li>

          <li><a routerLink="/privacy">Privacy Policy</a></li>

          <li><a routerLink="/faq">FAQ</a></li>

        </ul>
      </div>

      <!-- <div class="col-md-3 col-sm-6 col-xs-1">

        <h6 class="footer__title">Newsletter</h6>

        <p class="footer__text">
          Signup for our newsletter to get the latest news in your inbox.
        </p>

        <form action="#" class="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
          <div class="col text-center"><input class="form-control" id="txt_subscribe" name="txt_subscribe"
              placeholder="enter your email" type="text"><a aria-current="page" id="btn-subscribe" href="/"><i
                class="arrow_right bg-color-secondary"></i></a>
            <div class="clearfix"></div>
          </div>
        </form>

        <p>Your email is safe with us. We don't spam.</p>

      </div> -->


    </div>


  </div>

</footer>

<div class="copyright text-center">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-4">
        <a routerLink="/">
          <img src="assets/images/nft-logo.png" alt="logo">
        </a>
      </div>
      <div class="col-md-4"> © Copyright 2022 - Art-to-nft</div>
      <div class="col-md-4">
        <ul class="footer__social space-x-10 mb-40">
          <li> <a href="https://www.facebook.com/arttonft" target="_blank"> <i class="fa fa-facebook"></i> </a> </li>
          <li> <a href="https://twitter.com/arttonft?s=21&t=mXfBmM-qxFDp9shr3vGWgw" target="_blank"> <i class="fa fa-twitter"></i> </a> </li>
          <li> <a href="https://instagram.com/artto.nft?igshid=YmMyMTA2M2Y=" target="_blank"> <i class="fa  fa-instagram"></i> </a> </li>
          <li> <a href="#" target="_blank" ><i class="fab fa-discord"></i></a> </li>
        </ul>
      </div>
    </div>
  </div>
</div>