export const API = {
    base_url: 'https://api.art-to-nft.io',
    cms_url: "https://api.art-to-nft.io",
    user_login: "/user/login",
    user_register: "/user",
    user_forgot: "/user/forgot",
    user_reset: "/user/reset",
    user_profile: "/user/profile",
    user_update: "/user/update",

    item_check_balance: "/item/checkbalance",
    item_transfer_balance: "/item/sendeth",

    collection_add: "/collection/add",
    collection_update: "/collection/update",
    collection_detail: "/collection/detail",
    collection_delete: "/collection/delete",
    collection_list: "/collection/list",
    collection_generateabi: "/collection/generateabi",
    collection_hot: "/collection/hot-collections",

    category_list: "/category/list",
    topsellers:"/item/topSellers_up?history_type=transfer",
    item_add: "/item/add",
    item_update: "/item/update",
    item_delete: "/item/delete",
    item_list: "/item/list",
    item_bulkmintData: "/bulkMint/bulk-mint-token",
    item_favourite_list: "/item/favouriteslist",
    item_listbycollection: "/item/listbycollection",
    item_publish: "/item/publish",
    item_purchase: "/item/purchase",
    item_views_add: "/item/addviews",
    item_like_add: "/item/addfavourites",
    item_report: "/item/report",
    item_more_collection: "/item/morefromcollection",
    item_history: "/item/history",
    item_prices: "/item/prices",
    item_new:"/item/new-items",
    item_ipfsFolder: "/media/ipfsImage",
    bulkImageUpload: "/media/multiple_fileupload",

    item_offers: "/item/offers",
    item_offer_add: "/item/addoffer",
    item_offer_remove: "/item/removeoffer",
    item_offer_action: "/item/actionoffer",
    settings_option: "/settings/getoptions",

    user_register_new: "/user/register",
    user_login_new: "/user/login",

    menu_gettermsconditions: 'menu/gettermsconditions',
    menu_gettermsbyId: 'menu/gettermsconditions',

    menu_getHelpCenters: 'menu/gethelpcenter',
    menu_getHCbyId: 'menu/gethelpcenter',

    menu_getprivacypolicy: 'menu/getprivacypolicy',
    menu_getPPbyId: 'menu/getprivacypolicy',

    menu_getCareers: 'menu/getcareers',
    menu_getCareerbyId: 'menu/getcareers',

    menu_getSocialMedia: 'menu/getsocialmedia',
    menu_getSocialMediabyId: 'menu/getsocialmedia',

    menu_getContactUs: 'menu/getcontactus',
    menu_getContactUsbyId: 'menu/getcontactus',

    menu_getBlogs: 'menu/getblogs',
    menu_getBlogbyId: 'menu/getblogs',

    menu_getFaqs: 'menu/getfaq',
    menu_getFaqbyId: 'menu/getfaq',

    menu_getEmailtemps: 'menu/getemailtemplate',
    menu_getEmailtempbyId: 'menu/getemailtemplatebyid',
    
    menu_getHeaders: 'menu/headerfulllist',
    menu_getHeaderbyId: 'menu/getheaderbyid',
    
    menu_getFooters: 'menu/footerfulllist',
    menu_getFooterbyId: 'menu/getfooterbyid',
    menu_getHomepagebanner: 'menu/gethomepagebanner',

    menu_getSocialLinks: 'setting/getSocialLink',
}
